import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {Box, Container, Divider, Grid, Link, Paper, Stack, Typography} from '@mui/material';
import axios from 'axios';
import Loading from "../Loading";
import {Link as RouterLink} from 'react-router-dom';
import DollarPrice from "../DollarPrice";
import Percentage from "../Percentage";
import Toman from "../Toman";
import Blinker from "../Blinker";
import Ellipsis from "../Ellipsis";

interface Currency {
  name: string,
  symbol: string,
  slug: string,
  price: string,
  price_toman: string,
  cmcRank: number,
  cmcId: number,
  percentChange24h: string,
  percentChange30d: string,
  raise?: -1 | 0 | 1
}

interface SearchResultsProps {
  query: string
}

function CurrencyRow(props: { currency: Currency }) {
  return (
    <Paper className={'currency-row'} elevation={0}>
      <Grid container my={1}>
        <Grid item xs={1} sx={{textAlign: 'center', my: 'auto'}} className={'rank'}>
          <Typography variant='body1'>{props.currency.cmcRank}</Typography>
        </Grid>
        <Grid item xs={4} sx={{textAlign: 'center'}} justifyContent="center">
          <Stack direction='row'>
            <Box sx={{my: 'auto'}} className={'currency-logo'}>
              <RouterLink to={`/currencies/${props.currency.slug.toLowerCase()}`}>
                <img alt={props.currency.name} width={24}
                     src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${props.currency.cmcId}.png`}/>
              </RouterLink>
            </Box>
            <Stack sx={{textAlign: 'left', ml: 1}}>
              <Box>
                <Link className={'currency-detail-link'} underline="none" component={RouterLink}
                      to={`/currencies/${props.currency.slug.toLowerCase()}`}>
                  <Ellipsis text={props.currency.name}/>
                </Link>
              </Box>
              <Box>
                <Link underline="none" className={'currency-symbol-link'} component={RouterLink}
                      to={`/currencies/${props.currency.slug.toLowerCase()}`}>
                  <Typography className={'currency-symbol1'}
                              sx={{
                                fontSize: '15px',
                                fontWeight: '800'
                              }}
                              color={(theme) => theme.palette.text.primary}
                              fontSize='0.5rem'
                              >{props.currency.symbol.toUpperCase()}</Typography>
                </Link>
              </Box>
            </Stack>
          </Stack>
        </Grid>
        <Grid className={'currency-price'} item xs={5}
              sx={{textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
          <Box className={'dollar-price'}>
            <Blinker mode={props.currency.raise}>
              <DollarPrice price={props.currency.price}/>
            </Blinker>
          </Box>
          <Box className={'toman-price'} sx={{direction: 'rtl', color: 'grey'}}>
            <Toman
              irt={props.currency.price_toman}
              usd={props.currency.price}
              symbol='تومان'
            />
          </Box>
        </Grid>
        <Grid className={'changes-24h'} item xs={2} sx={{textAlign: 'center', my: 'auto'}}><Percentage
          value={props.currency.percentChange24h} compact /></Grid>
      </Grid>
      <Divider/>
    </Paper>
  );
}


function Currencies(props: { currencies: Currency[] }) {
  return (
    <Box>
      {props.currencies.map((currency) => (
        <CurrencyRow key={currency.cmcId} currency={currency}/>
      ))}
    </Box>
  );
}


export default function CurrencySearchResults(props: SearchResultsProps) {
  const [page, setPage] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(false)
  const [finished, setFinished] = useState<boolean>(false)
  const [wsUrl, setWsUrl] = useState<string>("")
  const [ticking, setTicking] = useState<boolean>(false);
  const [currencies, setCurrencies] = useState<Currency[]>([])
  const [updates, setUpdates] = useState<any>({});
  const [assets, setAssets] = useState("ALL");


  const getCurrencies = useCallback(() => {
    setLoading(true)
    setCurrencies([])
    axios.get(`https://appapi.ramzarz.news/api/cryptocurrency/listing?search=${props.query}`)
      .then(response => response.data)
      .then((data) => {
        setCurrencies(data.data.cryptoCurrencyList)

      }).catch(function (err) {
      if (err.code === "ERR_BAD_REQUEST" && err.response.status === 400 && err.response.data.code === "rest_post_invalid_page_number") {
        setFinished(true);
      }

    }).finally(() => {
      setLoading(false)
    });
  }, [currencies]);


  useEffect(() => {
    getCurrencies()
  }, [props.query]);

  return (
    <Container className="currencies-table custom-font-size" sx={{direction: 'ltr'}} disableGutters maxWidth={false}>
      <Typography sx={{direction: 'rtl', px: 2}} fontWeight={'bold'}>ارزهای یافت شده</Typography>
      <Currencies currencies={currencies}/>
      {
        loading && (
          <Box pt={3}>
            <Loading/>
          </Box>
        )
      }
    </Container>
  );
}
